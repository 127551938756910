import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { kebabCase } from 'lodash'
import { Link, graphql } from 'gatsby'
import {
  createMuiTheme,
  ThemeProvider,
  makeStyles,
} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Badge from '@material-ui/core/Badge'
import Chip from '@material-ui/core/Chip'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import CssBaseline from '@material-ui/core/CssBaseline'
import Layout from '../../components/Layout'
import CustomPageContext from '../../Context/CustomPageContext'
import MetaTags from '../../components/MetaTags'
import appConfig from '../../config/config'

const useStyles = makeStyles((theme) => ({
  section: {
    padding: '1.2rem 0rem',
  },
  titleContainer: {},
  descriptionContainer: {
    padding: '0.75rem 0',
  },
  tagContainer: {
    padding: '1rem 0rem',
  },
}))

const TagsPage = ({
  location,
  data: {
    allMarkdownRemark: { group },
    site: {
      siteMetadata: { title },
    },
  },
}) => {
  const initUiTheme =
    typeof window !== 'undefined' ? localStorage.getItem('uiTheme') : null
  const [uiTheme, setUiTheme] = useState(
    initUiTheme || appConfig.defaultInitTheme
  )
  const classes = useStyles()

  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          // type: prefersDarkMode ? 'dark' : 'light',
          type: uiTheme,
          primary: { main: '#212121' },
          secondary: { main: '#DD2C00' },
        },
      }),
    [uiTheme]
  )

  useEffect(() => {
    // Sets the theme config to localstore
    if (uiTheme) {
      localStorage.setItem('uiTheme', uiTheme)
    }
  }, [uiTheme])

  return (
    <CustomPageContext.Provider
      value={{
        uiTheme,
        setUiTheme,
      }}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Layout location={location}>
          <section className={classes.section}>
            <MetaTags pageTitle="Tags" />
            <Grid container direction="column" alignItems="center">
              <Grid
                item
                xs={11}
                sm={11}
                md={9}
                lg={8}
                xl={7}
                xxl={7}
                style={{ width: '100%' }}
              >
                <div className={classes.titleContainer}>
                  <Typography variant="h4">Tags</Typography>
                </div>
                <div className={classes.descriptionContainer}>
                  <Typography color="textSecondary">All tags</Typography>
                </div>
                <div className={classes.tagContainer}>
                  <Grid container spacing={3}>
                    {group.map((tag) => (
                      <Grid item key={`${tag.fieldValue}-tag`}>
                        <Link
                          to={`/tags/${kebabCase(tag.fieldValue)}/`}
                          style={{ textDecoration: 'none', color: 'unset' }}
                        >
                          <Badge
                            badgeContent={tag.totalCount}
                            color="secondary"
                          >
                            <Chip
                              size="medium"
                              avatar={<LocalOfferIcon style={{ width: 15 }} />}
                              label={tag.fieldValue}
                              clickable
                            />
                          </Badge>
                        </Link>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </section>
        </Layout>
      </ThemeProvider>
    </CustomPageContext.Provider>
  )
}

TagsPage.propTypes = {
  location: PropTypes.object,
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({ group: PropTypes.array }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({ title: PropTypes.string }),
    }),
  }),
}

export default TagsPage

export const tagPageQuery = graphql`
  query TagsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      filter: { frontmatter: { hidePost: { in: [false, null] } } }
    ) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
